import React, { useState, useEffect, useRef, useCallback } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import { Input, Button } from "@nextui-org/react";
import PaintingListItem from "./desktop-painting-list-item";
import PaintingDetailPage from "./desktop-painting-detail-page";
import axios from "axios";
import NavbarComponent from "./NavbarComponent";
import PaintXLogo from "./PaintXLogo";

const apiBaseUrl = "https://art.paintx.ru/paintings";

export default function DesktopApp({ className }) {
    const [paintings, setPaintings] = useState([]);
    const [page, setPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);
    const [hasMore, setHasMore] = useState(true);
    const [searchQuery, setSearchQuery] = useState(""); // Search query state

    const observer = useRef();
    const isFetching = useRef(false);

    const paintingsPerPage = 12;
    let splash = true;

    // Update search query and reset pagination
    const handleSearch = (query) => {
        setSearchQuery(query);
        setPage(1); // Reset to first page for new search
        setPaintings([]); // Clear existing paintings
    };

    useEffect(() => {
        if (!hasMore || isFetching.current) return; // Prevent overlapping requests and unnecessary fetches

        const fetchPaintings = async () => {
            // if (isFetching.current) return; // Prevent overlapping requests
            isFetching.current = true;
            setIsLoading(true);
            
            const source = axios.CancelToken.source();
            const timeout = setTimeout(() => {
                source.cancel("Timeout");
            }, 10000);

            try {
                const response = await axios.get(
                    `${apiBaseUrl}?page=${page}&limit=${paintingsPerPage}${searchQuery ? `&search=${searchQuery}` : ""}`,
                    { cancelToken: source.token }
                );

                const data = response.data;

                // Append new paintings to the list without filtering
                setPaintings((prevPaintings) => [...prevPaintings, ...data]);

                // Only set hasMore to false if fewer than paintingsPerPage items are returned
                if (data.length < paintingsPerPage) {
                    setHasMore(false);
                    console.log("No more paintings to fetch");
                }

            } catch (error) {
                clearTimeout(timeout);
                if (axios.isCancel(error)) {
                    console.log("Request canceled:", error.message);
                } else {
                    console.error("Error fetching paintings:", error.message);
                }
                // Retry on error or timeout without changing hasMore
                setTimeout(fetchPaintings, 10000);
            } finally {
                setIsLoading(false);
                isFetching.current = false; // Release the lock
            }
        };

        // Only fetch if there's more data and we're on the main page
        if (hasMore && location.pathname === "/") {
            fetchPaintings();
        }

        if (splash) {
            splash = false;
            function fadeOutLogo() {
                const logoContainer = document.getElementById("logoContainer");

                if (logoContainer) {
                    logoContainer.classList.add("fade-out");
                    setTimeout(() => {
                        logoContainer.classList.add("hidden");
                    }, 1000);
                }
            }
            setTimeout(fadeOutLogo, 1000);
        }
    }, [page, searchQuery, hasMore]);

    const lastPaintingRef = useCallback(
        (node) => {
            if (isLoading || !hasMore) return;
            if (observer.current) observer.current.disconnect();
            observer.current = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && !isFetching.current) {
                    // Increment page only if a request isn't in progress
                    setPage((prevPage) => prevPage + 1);
                }
            });
            if (node) observer.current.observe(node);
        },
        [isLoading, hasMore]
    );



    return (
        <Router>
            <NavbarComponent onSearch={handleSearch}/>

            <Routes>
                <Route
                    path="/"
                    element={
                        <div className="relative min-h-screen flex flex-col items-center">
                            <div className="gallery w-full p-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                                {paintings.map((painting, index) => {
                                    if (paintings.length - index <= 10) {
                                        return (
                                            <PaintingListItem
                                                key={painting.id}
                                                ref={lastPaintingRef}
                                                data-id={painting.id}
                                                data-src={painting.imageMidSrc}
                                                {...painting}
                                            />
                                        );
                                    } else {
                                        return (
                                            <PaintingListItem
                                                key={painting.id}
                                                data-id={painting.id}
                                                data-src={painting.imageMidSrc}
                                                {...painting}
                                            />
                                        );
                                    }
                                })}
                            </div>
                            {isLoading && <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10 h-36">
                                <div className="w-8 h-8 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
                            </div>}
                        </div>
                    }
                />
                <Route path="/art/:id" element={<PaintingDetailPage />} />
            </Routes>
        </Router>
    );
}
