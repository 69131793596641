import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom"; // Import Link for navigation
import axios from "axios";
import { Button, Chip } from "@nextui-org/react"; // Still using NextUI for buttons and chips
import PaintingScroller from "./painting-scroller"; // Import the scroller component
import ArtworkRating from "./ArtworkRating";
import PaintingPriceRange from "./PaintingPriceRange";

const apiBaseUrl = "https://art.paintx.ru/painting";

const PaintingDetailPage = () => {
  const { id } = useParams();
  const [painting, setPainting] = useState(null);
  const [relatedPaintings, setRelatedPaintings] = useState([]);
  const [relatedStyle, setRelatedStyle] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showSplash, setShowSplash] = useState(true); // Splash screen visibility
  const [error, setError] = useState(null);
  const [imageSrc, setImageSrc] = useState(""); // State for the main image source

  const maxRetries = 3; // Set the maximum number of retries for the API call
  const retryDelay = 1000; // Retry delay in milliseconds

  // Retry logic for API call
  const fetchPaintingDetailsWithRetry = async (retries) => {
    try {
      const response = await axios.get(`${apiBaseUrl}/${id}`);
      setPainting(response.data[0]);
      setImageSrc(response.data[0].image_mid_res_url); // Set image source after fetching the painting
      setError(null);
      
      setIsLoading(false); // Turn off loading state for the painting
    } catch (err) {
      if (retries > 0) {
        console.log(`Retrying API call... attempts left: ${retries}`);
        setTimeout(() => fetchPaintingDetailsWithRetry(retries - 1), retryDelay); // Retry with delay
      } else {
        setError("Не удалось загрузить данные о картине");
        setIsLoading(false);
      }
    }
  };

  // Use effect to fetch painting data with retries
  useEffect(() => {
    setIsLoading(true);
    fetchPaintingDetailsWithRetry(maxRetries); // Start fetching with retries
  }, [id]);

  // Splash screen logic: Hide after the main image is fully loaded
  const handleImageLoad = () => {
    setImageLoaded(true);  // Image is loaded, set visibility
    setTimeout(() => setShowSplash(false), 1000); // Fade out splash after 1 second
  };

  // Fetch related paintings lazily after the painting details load
  useEffect(() => {
    if (!painting) return;

    const fetchRelatedPaintings = async () => {
      try {
        const relatedResponse = await axios.get(`${apiBaseUrl}/${id}/related/artist`);
        const relatedResponseStyles = await axios.get(`${apiBaseUrl}/${id}/related/style`);
        setRelatedPaintings(relatedResponse.data);
        setRelatedStyle(relatedResponseStyles.data);
      } catch (err) {
        console.error("Failed to load related paintings");
      }
    };

    fetchRelatedPaintings();
  }, [painting]);

  // Loading or error states
  if (error) return <div className="p-10 text-center text-white">{error}</div>;
  if (isLoading) return (
    <div className="absolute top-20 inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10 h-36">
        <div className="w-8 h-8 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
    </div>)
  if (!painting) return <div className="p-10 text-center text-white">Картина не найдена</div>;

  return (
    <>
      {/* Main Content */}
      <div className={`flex flex-col lg:flex-row gap-10 p-10 bg-black text-white rounded-lg shadow-lg transition-opacity duration-1000 ${imageLoaded ? 'opacity-100' : 'opacity-0'}`}>
        {/* Left Section: Main Image */}
        <div className="lg:w-3/5">
          {/* Placeholder to ensure consistent layout */}
          <div className="relative">
            {!imageLoaded && (
                <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center items-center z-10 h-36">
            <div className="w-8 h-8 border-4 border-t-transparent border-white rounded-full animate-spin"></div>
          </div>
            )}
            <img
              src={imageSrc}
              onContextMenu={(e) => e.preventDefault()} // Disable right-click
              className="w-full h-auto rounded-lg shadow-lg object-cover"
              onLoad={handleImageLoad}  // Trigger splash removal once image loads
              //style={{ visibility: imageLoaded ? 'visible' : 'hidden' }} // Hide the image until it's loaded
            />
          </div>

          {/* Related Paintings Section below the main image */}
          <PaintingScroller
            title="Другие работы этого автора"
            fetchUrl={`${apiBaseUrl}/${id}/related/artist`}
            idPrefix="author"
            paintingId={id}
          />

          {/* Related Paintings Section for style */}
          <PaintingScroller
            title="Другие работы в этом стиле"
            fetchUrl={`${apiBaseUrl}/${id}/related/style`}
            idPrefix="style"
            paintingId={id}
          /> 
        </div>

        {/* Right Section: Painting Details */}
        <div className="flex flex-col gap-4 lg:w-2/5">
          <h1 className="text-3xl font-bold text-white">
            {painting.title_ru}
            {painting.year && painting.year > 0 ? `, ${painting.year}` : ""}
          </h1>

          <div className="text-xl font-bold text-gray-300">
            {painting.domestic_price > 0
              ? new Intl.NumberFormat("ru-RU", {
                  style: "currency",
                  currency: "RUB",
                }).format(painting.domestic_price)
              : "Цена по запросу"}
          </div>

          <div className="flex gap-2">
            {painting.availability && (
              <Chip className="bg-gray-700 text-white rounded-full text-sm font-medium">
                {painting.availability}
              </Chip>
            )}
            {painting.condition && (
              <Chip className="bg-gray-700 text-white rounded-full text-sm font-medium">
                {painting.condition}
              </Chip>
            )}
            {painting.artist_works_count > 1 && painting.artist_max_price !== painting.artist_min_price && (
                <PaintingPriceRange minPrice={painting.artist_min_price} maxPrice={painting.artist_max_price} paintingCount={painting.artist_works_count} currentPaintingPrice={painting.domestic_price} />
            )}  
          </div>

          <p className="text-sm text-gray-400 mt-4 leading-relaxed">{painting.description_ru}</p>

            {/* Artwork Rating */}
            {painting.notes_ru && (
              <ArtworkRating data={JSON.parse(painting.notes_ru)}/>
            )}

          <div className="flex flex-wrap gap-2 mt-4">

            {painting.style_name !== 'Неизвестный стиль' && (
              <Chip className="bg-gray-700 text-white rounded-full text-sm font-medium">
                {painting.style_name}
              </Chip>
            )}
            {painting.medium_name !== 'Неизвестно' && (
              <Chip className="bg-gray-700 text-white rounded-full text-sm font-medium">
                {painting.medium_name}
              </Chip>
            )}
            {painting.canvas_name !== 'Неизвестно' && (
              <Chip className="bg-gray-700 text-white rounded-full text-sm font-medium">
                {painting.canvas_name}
              </Chip>
            )}
            {painting.canvas_width && painting.canvas_height && (
              <Chip className="bg-gray-700 text-white rounded-full text-sm font-medium">
                {painting.canvas_width} x {painting.canvas_height} см
              </Chip>
            )}
            {/* if tags is present and is not empty, remove from it style, medium, canvas, author, year and add the rest as chips */}
            {painting.tags_ru &&
                JSON.parse(painting.tags_ru)
                    .filter(
                    (tag_ru) =>
                        ![painting.style_name, painting.medium_name, painting.canvas_name, painting.artist_name, painting.year].includes(
                        tag_ru
                        )
                    )
                    .map((tag_ru, index) => (
                    <Chip key={index} className="bg-gray-700 text-white rounded-full text-sm font-medium">
                        {tag_ru}
                    </Chip>
                    ))}
          </div>

          <div className="flex gap-2 mt-4">
            {painting.colors &&
              JSON.parse(painting.colors).map((color, index) => (
                <div key={index} className="flex items-center">
                  <div
                    className="cursor-default w-6 h-6 rounded-full border-2 border-white"
                    style={{ backgroundColor: color.hex }}
                  />
                </div>
              ))}
          </div>

          <div className="text-md text-white py-4">
            {painting.artist_name}
            <p
              className="text-sm text-gray-400 mt-4 leading-relaxed py=2"
              dangerouslySetInnerHTML={{ __html: painting.artist_about }}
            />
          </div>

          {/* Action Buttons */}
          <div className="flex gap-4 mt-6">
            <Button className="bg-blue-600 hover:bg-blue-500 text-white flex items-center gap-2 px-4 py-2 rounded-lg">
              Уведомить о скидках
            </Button>

            <Button className="bg-blue-600 hover:bg-blue-500 text-white flex items-center gap-2 px-4 py-2 rounded-lg">
              Уведомить о новых работах
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaintingDetailPage;
